import { configureStore } from "@reduxjs/toolkit";
import mainReducer from "./reducers/mainSlice";

BigInt.prototype.toJSON = function () {
  return this.toString();
};

export const store = configureStore({
  reducer: {
    main: mainReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["user/setUserData"],
        ignoredPaths: [],
      },
    }),
});
